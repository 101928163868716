.selWork {
  background-color: #ffffff;
  width: 100%;
  text-align: center;
}

.selWorkCaseStudyLinkSeparator {
  margin-top: 137px;
}

.selContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1025px;
  margin: auto;
  background-color: #ffffff;
}

.selWorkTextTop {
  height: 16px;
  width: 130px;
  color: #2c3e50;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 9px;
  margin-bottom: 8px;
}

.selWorkText {
  height: 42px;
  width: 184px;
  color: #2c3e50;
  font-family: Lato;
  font-size: 24px;
  line-height: 42px;
  margin: 0px 0px 45px 0px;
}

.selWorkSeparator {
  height: 2px;
  width: 100%;
  background-color: #ccebff;
  margin: 67px auto;
}

@media only screen and (max-width: 800px) {
  .selWorkCaseStudyLinkSeparator {
    display: none;
  }

  .selWorkText {
    margin: auto auto 20px auto;
  }

  .selContent {
    margin: auto auto 10px auto;
  }
}
