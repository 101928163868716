:root {
  --margin-lg: 109px;
  --max-width: 1025px;
  --navbar-height: 100px;
}

body {
  font-family: 'Lato', 'Helvetica', 'Ubuntu', sans-serif;
  /* font-weight: 100; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fefdfb;
  margin: auto;
  text-align: center;
}

a {
  cursor: pointer;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: #3d3d3d;
  font-family: Lato;
  font-size: 32px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 39px;
}

h2 {
  color: #3d3d3d;
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;

  margin: 0;
}

h3 {
  color: #3d3d3d;
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  line-height: 22px;
  font-weight: 500;

  margin: 0;
  margin-bottom: 12px;
}

p {
  color: #3d3d3d;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;

  margin: 0;
}

figcaption {
  font-family: 'Lato';
  color: #3d3d3d;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
  line-height: 15px;
  text-align: center;
}

/* this spacers can become a custom component */
.section-spacer {
  display: block;
  margin: 25px 0;
}

.section-half-space {
  display: block;
  margin: 12.5px 0;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.width-1 {
  max-width: 660px;
  min-width: 660px;
}

.width-2 {
  max-width: 720px;
  min-width: 720px;
}

.width-3 {
  max-width: 780px;
  min-width: 780px;
}

.col-container {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;

  justify-content: space-between;

  margin: auto var(--margin-lg) auto var(--margin-lg);
}

.col {
  display: flex;
  flex-direction: column;
}

.col-divider {
  display: block;
  margin: 0 1vw;
}

.col-divider-big {
  display: block;
  margin: 0 5vw;
}

.sx-margin {
  margin: auto;
}

.flex {
  display: flex !important;
}

.flex-start {
  justify-content: flex-start;
}

.white h1,
.white h2,
.white h3,
.white p {
  color: #fff;
}

.no-margin h1,
.no-margin h2,
.no-margin h3,
.no-margin p {
  margin: 0;
}

.cs-selector a {
  color: #e8ecef;
}

.cs-selector a:visited {
  color: #e8ecef;
}

@media only screen and (max-width: 800px) {
  :root {
    --margin-lg: 20px;
    --navbar-height: 14vw;
  }

  .col-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: auto var(--margin-lg) auto var(--margin-lg);
  }

  .col {
    margin: 15px auto 15px auto;
  }

  .sx-margin {
    margin: auto 20px;
  }

  .section-spacer {
    margin: 4vw;
  }

  .width-1 {
    max-width: 660px;
    min-width: auto;
  }

  .width-2 {
    max-width: 720px;
    min-width: auto;
  }

  .width-3 {
    max-width: 780px;
    min-width: auto;
  }
}
