.ctaWrapper {
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  margin-top: var(--navbar-height);
}

.ctaContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  max-width: 1025px;
  margin: auto;
  background: #ffffff;
}

.ctaLeftBox {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
}

.ctaLeftBox h1 {
  display: block;
  color: #2c3e50;
  font-family: Lato;
  font-size: 50px;
  line-height: 60px;
  white-space: nowrap;
  font-weight: 900;
  margin: 140px 0px 30px 64px;
}

.ctaText {
  text-align: left;
  /* height: 216px;
  width: 381px; */
  color: #2c3e50;
  font-family: Lato;
  font-size: 20px;
  font-weight: 500;
  font-style: regular;
  line-height: 24px;
  margin: 0px 0px 40px 64px;
}

.ctaButtonBox {
  display: block;
  cursor: pointer;
  height: 50px;
  width: 328px;
  border-radius: 4px;
  background-color: #ffaf32;
  margin: 0px 0px 40px 64px;
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-color: #ffaf32;
  transition: ease-in-out 0.5s;
}

.ctaButtonBox:hover {
  border-style: solid;
  border-color: #2c3e50;
}

.ctaButtonBox div {
  display: block;
  height: 24px;
  width: 182px;
  color: #2c3e50;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;

  padding: 13px 72px;

  text-decoration: none;
}
.ctaRightBox {
  display: flex;
  flex-direction: column;

  transition: ease-in 0.5s;
}

.ctaRightBox img {
  display: block;
  margin: 23px auto auto 23px;
}

@media only screen and (max-width: 800px) {
  .ctaRightBox {
    display: none;
  }
  .ctaLeftBox {
    width: 100%;
  }

  .ctaLeftBox h1 {
    font-size: 30px;
    margin: auto;
  }

  .ctaText {
    margin: auto auto 10px auto;
    width: 90%;
    text-align: center;
    white-space: normal;
  }

  .ctaText br:nth-child(1) {
    display: none;
  }

  .ctaText br:nth-child(2) {
    display: none;
  }

  .ctaButtonBox {
    margin: auto auto 20px auto;
  }
}
