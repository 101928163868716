.project-overview {
  text-align: left;
  margin: auto var(--margin-lg) auto var(--margin-lg);
}

.fitStudyOverviewIconsSection {
  display: flex;
  margin: auto;
  align-items: center;
  max-width: 1025px;
  background-color: red;
}

.data-section-header {
  display: flex;
  align-self: flex-start;

  color: #3d3d3d;
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;

  /* margin: auto var(--margin-lg) auto var(--margin-lg); */
}

.data-section-sub-header {
  display: flex;
  align-self: flex-start;
  text-align: left;

  color: #3d3d3d;
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  line-height: 22px;

  /* margin: 2px var(--margin-lg) auto var(--margin-lg); */
}

.data-section-paragraph {
  display: flex;
  align-self: flex-start;

  text-align: left;

  /* margin: auto var(--margin-lg) auto var(--margin-lg); */
}

.data-section-paragraph ul {
  margin: 0;
  padding-left: 24px;
}

li {
  margin: 10px 0;
}

/* revise from here down */

.trainers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  align-items: center;

  margin: 0px var(--margin-lg) auto var(--margin-lg);
}

.avatarStyle {
  width: 105px;
  height: auto;
}

.trainerBox {
  align-self: flex-start;
  margin-left: 14px;
}

.white h2,
.white h3,
.white p {
  color: #fafdff;
}

.workshop-p2 {
  text-align: left;
  margin: auto var(--margin-lg) auto var(--margin-lg);
}

workshop-p2 > ol {
  margin: 0;
}

.workshop-p2-dotimg-container {
  display: block;
  text-align: center;
}

.workshop-p2 > img {
  /* display: flex; */
  /* align-self: center; */
  margin: auto;
}

.workshop-p2-list-item {
  color: #3d3d3d;
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  line-height: 19px;
}

.user-flow-img-container {
  max-width: 1025px;
  margin: auto var(--margin-lg) auto var(--margin-lg);
}

.image-text-container {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  text-align: left;
  align-items: flex-start;

  margin: 0px var(--margin-lg) auto var(--margin-lg);
}

.image-text-left {
  display: flex;
  flex-direction: column;
}

.top-space {
  display: block;
  margin: 46px 0;
}

.image-text-right {
  display: flex;
  flex-direction: column;
}

.image-text-divider {
  display: block;
  margin: 0 2vw;
}
@media only screen and (min-width: 800px) {
  .sticky {
    position: fixed;
    top: calc(var(--navbar-height) + 40px);
  }
}

@media only screen and (max-width: 800px) {
  .image-text-container {
    flex-wrap: wrap;
    justify-content: center;
  }

  .wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .image-text-left {
    margin: 4vw 0;
  }

  .top-space {
    margin: 4vw 0;
  }

  .workshop-p1 {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .workshop-p1-textbox {
    margin: auto;
    margin-bottom: 4vw;
  }

  .trainer-icons {
    justify-content: center;
  }
  .trainer-icons img {
    margin-bottom: 4vw;
  }
}
