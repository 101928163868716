
.aboutWrapper {
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  margin-top: var(--navbar-height);

}

.aboutContent {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 1025px;
  margin: auto;
  background-color: #ffffff;
}

.topWrapper {
  display: flex;
  flex-direction: column;

}

.aboutName {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 65px;
  /* margin-left: 89px; */
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: left;
  height: 52px;
  color: #2c3e50;
  font-family: Lato;
  font-size: 44px;
  font-weight: bold;
  line-height: 52px;

  white-space: nowrap;
}

.aboutSection {
  display: flex;
  flex-direction: row;
  margin: 0;
  flex-wrap: wrap;
  justify-content: center;
}

.aboutGraphics {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.aboutPic {
  margin-top: 5px;
  margin-bottom: 39px;
}

.picSubtitle {
  height: 68px;
  width: 301px;
  color: #4a4a4a;
  font-family: 'Lato';
  font-size: 25px;
  font-style: italic;
  font-weight: 600;
  line-height: 34px;
  text-align: center;

  margin-bottom: 49px;
}

.experience {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 359px;
  height: 95px;

  margin-bottom: 35px;
}

.experienceLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
}

.experienceTitle {
  height: 19px;
  width: 121.79px;
  color: #2c3e50;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}

.experienceText {
  height: 19px;
  width: 94px;
  color: #181818;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;

  white-space: nowrap;
  text-align: right;
}

.characteristics {
  width: 360px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.aboutPillRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 16px;
}

.aboutPillContainer {
  display: flex;
  border-radius: 4px;
  background-color: #2c3e50;

  cursor: pointer;
  margin-right: 9px;
}

.aboutPillContainer p {
  margin: auto;

  color: #ffffff;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.aboutText {
  margin-left: 69px;
  text-align: left;
}

.aboutHeader {
  height: 30px;
  width: 108px;
  color: #2c3e50;
  font-family: 'Lato';
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  
  margin-bottom: 0.5em;

}

.paragraphText {
  /* height: 456px; */
  width: 456px;
  color: #181818;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;

  text-align: left;
  margin-top: 0.5em;

}

.goalSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 1.5em;
}

.goalSectionF {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 1.5em;
}

.goals {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5%;
}

.goalCircleBullet {
  background: #ffaf32;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  margin-right: 12px;
}

.goalText {
  width: 435.27px;
  color: #181818;
  font-family: Lato;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  line-height: 19px;
}

.otherSection {
  width: 100%;
  /* height: 402px; */
  background-color: #2d3e50;
}

.otherWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 1025px;
  margin: auto;
  margin-top: 30px;
  /* background-color: darkgreen; */
}
.otherSectionHeader {
  /* height: 44px; */
  /* width: 530px; */
  color: #fafdff;
  font-family: Lato;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;

  margin: 0px 0 0 70px;
  text-align: left;
}

.hobbies {
  margin: 0px 70px 0 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.myHobby img {
  height: 140px;
  width: 205px;
}

.myHobby p {
  margin-top: 5px;

  color: #fafdff;
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.aboutContact {
  margin: 0 auto;
  margin-top: -30px;
  margin-bottom: 1px;
  width: 70%;
  height: 100px;
  background-color: #ccebff;
  text-align: left;
}

.cliftonStrenghts {

 font-family: 'Lato';
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  
  margin-bottom: 0.5em;
  text-align: left;
  align-self: flex-start;


}

@media only screen and (max-width: 800px) {
  .aboutText {
    margin-top: 12px;
  }

  .aboutName {
    margin: auto auto 10px auto;
    text-align: center;
  }

  .otherSectionHeader {
    margin: 10px auto auto auto;
    /* height: auto; */
    text-align: center;
    width: 60%;
    clear: both;
  }

  .hobbies {
    margin-top: 10px;
    justify-content: center;
  }

  .aboutText {
    /* margin: auto; */
    margin: 20px 10px auto 10px;
  }

  .paragraphText {
    margin: auto;
    height: auto;
    width: auto;
  }

  .goalText {
    width: 100%;
    margin: auto;
  }
}
